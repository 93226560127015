
import { Box, Button, IconButton, LinearProgress, Link, Stack } from '@mui/joy';
import { useOutletContext, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVGClose, SVGExternalLink, SVGHelp } from 'icons';
import { SVGBack, SVGSave } from 'icons/header';
import { useNavigateKeepParams } from 'hooks';
import { LocaleSelector } from './locale-selector/LocaleSelector';
import { Logo } from './logo/Logo';
import { SaveModal } from './modal/SaveModal';

import './PageHeader.scss';
import { ExitModal } from './modal/ExitModal';

const HELP_LINK = 'https://support.askyourteam.com/kb/guide/en/participant-faq-u8v2vaZAUb/Steps/1815324';

export const HEADER_HEIGHTS = {
  xs: '86px',
  md: '128px',
};

export const HEADER_AND_PROGRESS_BAR_HEIGHT = {
  xs: '92px',
  md: '160px'
};

export const PageHeader = (props) => {
  const {
    hideLogo,
    hideBackToWelcomePage,
    hideHelp,
    hideSaveOrExit,
    percentage_complete
  } = props;

  const {
    image,
    account_organisation_name,
    locales,
    is_kiosk_mode,
    browser_lock
  } = useOutletContext() || {};

  const hasProgressBar = percentage_complete != undefined;

  const { feedbackID } = useParams();
  const { t } = useTranslation();
  const navigateKeepParams = useNavigateKeepParams();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);

  const onBackToWelcomePageClick = useCallback(
    () => {
      navigateKeepParams(`/${feedbackID}`);
    },
    [navigateKeepParams, feedbackID]
  );

  const onSaveClick = useCallback(
    () => {
      setSaveModalOpen(true);
    },
    []
  );

  const onExitClick = useCallback(
    () => {
      browser_lock ? setExitModalOpen(true) : setSaveModalOpen(true);
    },
    [browser_lock]
  );

  const backIcon = <SVGBack size={16} />;

  return (
    <Box
      component={'header'}
      sx={{
        height: hasProgressBar ? HEADER_AND_PROGRESS_BAR_HEIGHT : HEADER_HEIGHTS,
        backgroundColor: 'white',
      }}
    >
      <Link
        className={'header__skip-link'}
        href={'#main'}
        px={1}
      >
        {t('Skip to main content')}
      </Link>
      <Stack
        pt={{ sm: 1, md: 3 }}
        mb={{ xs: 2, md: 4 }}
        spacing={2}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
        >
          {!hideLogo && (
            <Logo
              image={image}
              account_organisation_name={account_organisation_name}
              sx={{ maxHeight: { xs: 40, md: 64 }, maxWidth: { xs: 40, md: 64 } }}
            />
          )}
          {!hideBackToWelcomePage && (
            <>
              <IconButton
                variant={'plain'}
                sx={{ display: { xs: 'block', md: 'none' } }}
                onClick={onBackToWelcomePageClick}
                aria-label={t('Back to welcome page')}
              >
                {backIcon}
              </IconButton>
              <Button
                data-component={'back-button'}
                variant={'plain'}
                color={'neutral'}
                startDecorator={backIcon}
                sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                onClick={onBackToWelcomePageClick}
              >
                {t('Welcome page')}
              </Button>
            </>
          )}
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
        >
          {!hideHelp && (
            <>
              <IconButton
                variant={'outlined'}
                sx={{ display: { xs: 'block', md: 'none' } }}
                onClick={() => window.open(HELP_LINK, '_blank')}
                aria-label={t('Get help')}
              >
                <SVGHelp size={16} />
              </IconButton>
              <Link
                data-component={'help-button'}
                href={HELP_LINK}
                underline={'always'}
                endDecorator={<SVGExternalLink size={12} />}
                sx={{ display: { xs: 'none', md: 'flex' } }}
                target={'_blank'}
                rel={'noreferrer'}
              >
                {t('Get help')}
              </Link>
            </>
          )}

          {!hideSaveOrExit && (
            <>
              {is_kiosk_mode ? (
                <>
                  <IconButton
                    variant={'outlined'}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    aria-label={t('Exit')}
                    onClick={onExitClick}
                  >
                    <SVGClose size={16} />
                  </IconButton>
                  <Button
                    data-component={'exit-button'}
                    variant={'outlined'}
                    color={'neutral'}
                    startDecorator={<SVGClose size={16} />}
                    sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                    onClick={onExitClick}
                  >
                    {t('Exit')}
                  </Button>
                  {browser_lock ? (
                    <ExitModal
                      open={exitModalOpen}
                      setOpen={setExitModalOpen}
                      feedbackID={feedbackID}
                    />
                  ) : (
                    <SaveModal
                      open={saveModalOpen}
                      setOpen={setSaveModalOpen}
                      feedbackID={feedbackID}
                    />
                  )}
                </>
              ) : (
                <>
                  <IconButton
                    variant={'outlined'}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    aria-label={t('Save')}
                    onClick={onSaveClick}
                  >
                    <SVGSave size={16} />
                  </IconButton>
                  <Button
                    data-component={'save-button'}
                    variant={'outlined'}
                    color={'neutral'}
                    startDecorator={<SVGSave size={16} />}
                    sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                    onClick={onSaveClick}
                  >
                    {t('Save')}
                  </Button>
                  <SaveModal
                    open={saveModalOpen}
                    setOpen={setSaveModalOpen}
                    feedbackID={feedbackID}
                  />
                </>
              )}
            </>
          )}

          {locales?.length > 1 && (
            <LocaleSelector locales={locales} />
          )}
        </Stack>
      </Stack>
      {percentage_complete != undefined && (
        <LinearProgress
          determinate
          value={percentage_complete}
          sx={{ mt: { xs: 0, md: 3 }, width: { xs: '100%' } }}
          aria-label={'survey progress'}
        />
      )}
    </Box>
  );
};

PageHeader.defaultProps = {
  hideBackToWelcomePage: false,
  hideHelp: false,
  hideSaveOrExit: false,
};
