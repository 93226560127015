import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Typography } from '@mui/joy';
import { useResetKiosk } from 'hooks';
import { ModalWrapper } from './ModalWrapper';

export const ExitModal = ({ open, setOpen, feedbackID }) => {
  const { t } = useTranslation();

  const { survey_uuid } = useOutletContext();

  const onClose = useCallback(
    () => setOpen(false),
    [setOpen]
  );

  const { onResetKiosk } = useResetKiosk({ feedbackID, survey_uuid });

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onClick={onResetKiosk}
      title={t('Are you sure you want to exit?')}
      body={(
        <div data-component={'exit-modal'}>
          <Typography mb={3}>
            <strong>
              {t("If you exit, your progress won't be saved.")}
            </strong>
          </Typography>
          <Typography>
            {t("That means you'll have to start from the beginning if you come back later.")}
          </Typography>
        </div>
      )}
      cancelButton={t('Back to survey')}
      continueButton={t('Exit')}
    />
  );
};
