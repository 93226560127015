import { Box, FormControl, FormHelperText, FormLabel, Input, Typography } from '@mui/joy';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { emailRegex } from 'lib/Utils';
import { postAPI } from 'lib/APIHelpers';
import { useNavigateKeepParams, useResetKiosk } from 'hooks';
import { ModalWrapper } from './ModalWrapper';

const AnonymousRespondent = ({
  t,
  is_kiosk_mode,
  onSaveAndExitClicked,
  email,
  error,
  onChangeEmail,
  browser_lock
}) => (
  <>
    <Typography mb={3}>
      {t("Enter your email address and we'll send you a link to finish the survey. Don't worry, your answers will still be anonymous.")}
    </Typography>
    <Box
      component={'form'}
      onSubmit={onSaveAndExitClicked}
      sx={{ maxWidth: 320, margin: '0 auto' }}
    >
      <FormControl
        sx={{ marginBottom: 3 }}
        error={error != undefined}
      >
        <FormLabel>{t('Enter your email')}</FormLabel>
        <Input
          sx={{ width: { xs: '100%', md: 320 } }}
          value={email}
          onChange={onChangeEmail}
          placeholder={'name@organisation.com'}
          name={'email'}
          slotProps={{
            input: { 'data-component': 'email-input' }
          }}
        />
        <FormHelperText
          aria-live={'polite'}
          data-component={'email-error'}
        >
          {error}
        </FormHelperText>
      </FormControl>
    </Box>
    {!browser_lock && !is_kiosk_mode && (
      <Typography mb={3}>
        <Trans i18nKey={"Don't want to provide your email address? If you're using a personal computer or phone, you can also copy and save this URL, or bookmark the page. Then return when you're ready."}>
          <strong>Don't want to provide your email address?</strong> If you're using a personal computer or phone, you can also copy and save this URL, or bookmark the page. Then return when you're ready.
        </Trans>
      </Typography>
    )}
  </>
);

export const SaveModal = ({ open, setOpen, feedbackID }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();
  const { t } = useTranslation();

  const {
    end_date,
    anonymous,
    is_kiosk_mode,
    survey_uuid,
    browser_lock
  } = useOutletContext();

  const endDate = useMemo(
    () => {
      if (!end_date) return;
      const date = new Date(end_date);
      return date.toLocaleString(
        undefined,
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }
      );
    },
    [end_date]
  );

  const onChangeEmail = useCallback(
    (e) => {
      setEmail(e.target.value);
      setError(undefined);
    },
    []
  );

  const onClose = useCallback(
    () => setOpen(false),
    [setOpen]
  );

  const navigateKeepParams = useNavigateKeepParams();
  const onSaveAndExitClicked = useCallback(
    async (e) => {
      if (anonymous) {
        e.preventDefault();
        if (!emailRegex.test(email)) {
          setError(t('Please enter a valid email.'));
          return;
        }

        try {
          await postAPI(
            `api/v2/feedback/${feedbackID}/save_survey`,
            { email: email }
          );

          navigateKeepParams('../progress_saved');
        } catch (e) {
          if (e.response?.status == 422) {
            setError(t('Please enter a valid email.'));
          } else {
            setError(e.message);
          }
        }

      } else {
        navigateKeepParams('../progress_saved');
      }
    },
    [anonymous, navigateKeepParams, email, t, feedbackID]
  );

  const { onResetKiosk } = useResetKiosk({ feedbackID, survey_uuid });

  const saveButtonText = anonymous
    ? t('Send link and exit')
    : t('Save and exit');

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onClick={onSaveAndExitClicked}
      onSecondaryClick={is_kiosk_mode ? onResetKiosk : undefined}
      title={t('Want to finish your survey later?')}
      body={(
        <div data-component={'save-modal'}>
          {anonymous ? (
            <AnonymousRespondent
              t={t}
              is_kiosk_mode={is_kiosk_mode}
              onSaveAndExitClicked={onSaveAndExitClicked}
              email={email}
              error={error}
              onChangeEmail={onChangeEmail}
              browser_lock={browser_lock}
            />
          ) : (
            <Typography mb={3}>{t('No problem')}</Typography>
          )}

          <Typography id={'modal-description'} mb={browser_lock ? 3 : undefined}>
            <Trans i18nKey={'This survey closes on'} endDate={endDate}>
              This survey closes on <strong>{{ endDate }}.</strong> Please make sure to finish it before then.
            </Trans>
          </Typography>

          {browser_lock && (
            <Typography>
              <strong>{t("Remember, you must complete your survey using the same browser and device you're using now.")}</strong>
            </Typography>
          )}
        </div>
      )}
      cancelButton={t('Back to survey')}
      continueButton={saveButtonText}
      secondaryButton={is_kiosk_mode ? t('Exit') : undefined}
    />
  );
};
